window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('summernote');
    require('admin-lte');
    require('sweetalert');
    require('select2');
    window.moment = require('moment');
    window.Chart = require('chart.js');
    window.html2canvas = require('html2canvas');
    require("flatpickr");
    require("jquery-file-upload");
    require("daterangepicker");
    require('../../public/js/autoNumeric.js');
    require('../../public/plugin/orgchart/jquery.orgchart.js');
    require('../../public/plugin/sticky_notes/js/jquery.stickynote.js');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import 'jquery-ui';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
// import 'summernote-bs4';
import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-colreorder';
import 'datatables.net-colreorder-bs4';

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
